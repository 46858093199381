import React from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaMoneyCheckAlt,FaCreditCard,FaUsers, FaInfoCircle } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { FcAbout } from "react-icons/fc";


const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("accessToken"); // Remove access token
    localStorage.removeItem("refreshToken"); // Optional: Remove refresh token if stored
    navigate("/login"); // Navigate to login page
  };

  return (
    <div className="hidden font-semibold lg:block">
      <Menu
      className="text-lg h-screen z-0 fixed w-[15%]"
      style={{borderLeft:"solid 1px #d1d5db"}}
      onClick={(item) => {
        if (item.key === "/log-out") {
          handleLogout();
        } else {
          navigate(item.key);
        }
      }}
        selectedKeys={[location.pathname]}
        items={[
          {
            label: "خانه",
            icon: <FaHome size={16}/>,
            key: "/",
          },
          {
            label: "فیش‌ها",
            icon: <FaMoneyCheckAlt size={16}/>,
            key: "/fesh",
          },
          {
            label: "مشتریان",
            icon: <IoIosPeople size={16} />,
            key: "/customer",
          },
          {
            label: "حسابات",
            icon: <FaCreditCard size={16} />,
            key: "/account",
          },
          {
            label: "کاربران",
            icon: <FaUsers className="text-gray-600" size={16}/>,
            key: "/user",
          },
          {
            label: "درباره ما",
            icon: <FaInfoCircle   size={16}/>,
            key: "/about-us",
          },
          {
            type: "divider",
            style:{color:"#d1d5db"}
          },
          {
            label: "خروج",
            icon: <RiLogoutCircleRFill size={16}/>,
            key: "/log-out",
            style: { marginTop: "40px" },
          },
        ]}
      ></Menu>
    </div>
  );
};

export default SideBar;

import React, { useState} from "react";
import { useQuery } from "@apollo/client";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { GET_GRAPH_STATISTIC } from "../../GraphQL/Query";
import { Alert, Select, Spin } from "antd";
import moment from "moment-jalaali"; // For Solar Hijri Calendar
import {useNavigate} from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "گراف گزارشات",
    },
  },
};

const labels = [
  "حمل",
  "ثور",
  "جوزا",
  "سرطان",
  "اسد",
  "سنبله",
  "میزان",
  "عقرب",
  "قوس",
  "جدی",
  "دلو",
  "حوت",
];

export default function Chart() {
  const [selectedYear, setSelectedYear] = useState(moment().jYear());
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_GRAPH_STATISTIC, {
    variables: { year: selectedYear },
    onError: (err) => {
      if (err.message.includes("Invalid token Or you are unauthorized")) {
        // Token has expired, handle logout
        localStorage.removeItem("token");
        navigate("/login");
      }
    },
  });

  const handleYearChange = (value) => {
    setSelectedYear(parseInt(value, 10));
  };

  // Generate years from 1400 to the current year + 1
  const yearOptions = Array.from(
    { length: selectedYear - 1399 + 2 },
    (_, i) => 1400 + i
  );

  if (loading)
    return (
      <p>
        <Spin />
      </p>
    );
  if (error)
    return (
      <Alert
        message="خطا"
        description={error.message}
        type="error"
        showIcon
      />
    );

  const chartData = {
    labels,
    datasets: [
      {
        label: "تعداد فیش ها",
        data: data.getGraphStatistic.map((item) => item.billsCount),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "تعداد مشتریان",
        data: data.getGraphStatistic.map((item) => item.customersCount),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "تعداد حسابات",
        data: data.getGraphStatistic.map((item) => item.accountsCount),
        backgroundColor: "rgba(34, 197, 94, 0.5)",
      },
    ],
  };

  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto">
      {/* Year Select Box */}
      <div className="flex items-center justify-center gap-2 mb-4 sm:justify-start">
        <h1 htmlFor="year-select" className="text-lg font-bold">
          انتخاب سال
        </h1>
        <Select
          value={selectedYear}
          style={{ width: 120 }}
          onChange={handleYearChange}
          options={yearOptions.map((year) => ({
            value: year.toString(),
            label: year.toString(),
          }))}
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full max-w-4xl mx-auto">
        {/* Chart */}
        <div className="w-full h-64 md:h-96">
          <Bar options={options} data={chartData} />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "../Fesh/Fesh.css";
import HeaderContent from "../../Components/HeaderContent/HeaderContent";
import TableComponent from "../../Components/Table/TableComponent";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import {
	Button,
	Input,
	Modal,
	notification,
	Popconfirm,
	Select,
	Space,
	Tag,
} from "antd";
import { MdAdd, MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useApolloClient } from "@apollo/client";
import { ACCOUNTS, GET_BILL } from "../../GraphQL/Query";
import { useLocation } from "react-router-dom";
import {
	CREATE_TRANSACTION,
	DELETE_TRANSACTION,
	EDIT_TRANSACTION,
} from "../../GraphQL/Mutation";
import { formatNumberWithCommas } from "../../utils/formatNumberWithCommas";

const MONTHS = [
	"حمل",
	"ثور",
	"جوزا",
	"سرطان",
	"اسد",
	"سنبله",
	"میزان",
	"عقرب",
	"قوس",
	"جدی",
	"دلو",
	"حوت",
];

export default function FeshDetails() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();

	const location = useLocation();
	const client = useApolloClient();
	const [dataSource, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [sender, setSender] = useState("");
	const [receiver, setReceiver] = useState("");
	const [searching, setSearching] = useState("");
	const [totalAmount, setTotalAmount] = useState(0);
	const [filteredData, setFilteredData] = useState([]); // Add this state for filtered data
	const [newTransaction, setNewTransaction] = useState({
		amount: "",
		account: "",
		followUpCode: "",
		date: "",
		branch: "",
	});
	const [selectedTransaction, setSelectedTransaction] = useState();

	const [page, setPage] = useState(1);
	const { billId } = location.state || {};
	useEffect(() => {
		if (billId) {
			loadBill();
			loadAccounts();
		}
	}, [billId, refresh]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "حساب",
			dataIndex: "account",
			key: "account",
		},
		{
			title: "مبلغ",
			dataIndex: "amount",
			key: "amount",
			render: (amount) => formatNumberWithCommas(amount),
		},
		{
			title: "شعبه",
			dataIndex: "agyncy",
			key: "agyncy",
		},
		{
			title: "پیگیری",
			dataIndex: "followUpCode",
			key: "followUpCode",
			filteredValue: [searching],
			onFilter: (value, record) => {
				return String(record.followUpCode).includes(value);
			},
			render: (text, record) => (
				<Tag color={record.isDuplicate ? "error" : "success"}>{text}</Tag>
			), // Render the Tag component in the UI
		},
		{
			title: "عملیات",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<MdOutlineEdit
						className="text-base cursor-pointer"
						onClick={() => handleEditTransaction(record?.key)}
					/>
					<Popconfirm
						title="آیا مطمین هستید میخواهید حذف نمایید؟"
						onConfirm={() => handleDeleteTransaction(record.key)}
						okText="بله"
						cancelText="انصراف"
						placement="topLeft">
						<MdOutlineDeleteOutline
							className="text-base cursor-pointer"
							color="red"
						/>
					</Popconfirm>
				</Space>
			),
		},
	];

	// add accounts
	async function loadAccounts() {
		setLoading(true);
		try {
			const res = await client.query({
				query: ACCOUNTS,
				variables: {
					filter: { page: 1 },
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setAccounts(res.data.getAccounts.accounts);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for getBills
	async function loadBill() {
		setLoading(true);
		try {
			const res = await client.query({
				query: GET_BILL,
				variables: { billId: billId },
				fetchPolicy: "no-cache",
			});
			if (res) {
				const sender = `${res.data.getBill.sender.firstName} ${res.data.getBill.sender.lastName}`;
				const receiver = `${res.data.getBill.reciever.firstName} ${res.data.getBill.reciever.lastName}`;
				setSender(sender);
				setReceiver(receiver);
				const transactions = res.data.getBill.transactions;

				// Find duplicate followUpCodes
				const followUpCodeCount = transactions.reduce((acc, transaction) => {
					acc[transaction.followUpCode] =
						(acc[transaction.followUpCode] || 0) + 1;
					return acc;
				}, {});
				const modifiedData = res.data.getBill.transactions.map(
					(transaction, index) => ({
						key: transaction.id,
						num: index + 1,
						date: transaction.date,
						account: `${transaction.account?.accountName}-${transaction.account?.accountNumber}`,
						amount: transaction.amount,
						agyncy: transaction.branch,
						followUpCode: transaction.followUpCode,
						isDuplicate: followUpCodeCount[transaction.followUpCode] > 1, // Flag to indicate duplicate
					})
				);
				// Calculate total amount
				const totalAmount = res.data.getBill.transactions.reduce(
					(sum, transaction) => sum + transaction.amount,
					0
				);
				setTotalAmount(formatNumberWithCommas(totalAmount));
				setDataSource(modifiedData);
				setFilteredData(modifiedData);
			}
		} catch (error) {
			console.error("Error fetching bill data:", error);
		} finally {
			setLoading(false);
		}
	}
	// function for createTransaction
	async function handleCreateTransaction(e) {
		e.preventDefault();
		try {
			const billId = location.state.billId;
			const res = await client.mutate({
				mutation: CREATE_TRANSACTION,
				variables: {
					billId,
					data: {
						date: newTransaction.date || today,
						branch: newTransaction.branch,
						followUpCode: newTransaction.followUpCode,
						account: newTransaction.account, // Use the selected account ID
						amount: Number(newTransaction.amount),
					},
				},
			});
			if (res) {
				const account = accounts.find(
					(acc) => acc.id === newTransaction.account
				);
				// Update dataSource state with new transaction
				const newTransactionData = {
					key: dataSource.length + 1,
					num: dataSource.length + 1,
					date: newTransaction.date || today,
					account: `${account.accountName} - ${account.accountNumber}`,
					amount: newTransaction.amount,
					agyncy: newTransaction.branch,
					followUpCode: newTransaction.followUpCode,
				};
				setDataSource([...dataSource, newTransactionData]);
				loadBill();
				setIsAdding(false);
				notification.success({
					message: "انتقال جدید با موفقیت اضافه شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}
	// function for deleteTransaction
	async function handleDeleteTransaction(transactionId) {
		try {
			const billId = location.state.billId;

			const res = await client.mutate({
				mutation: DELETE_TRANSACTION,
				variables: {
					billId,
					transactionId,
				},
			});
			// const updatedDataSource = dataSource.filter(
			// 	(transaction) => transaction.key !== transactionId
			// );
			// setDataSource(updatedDataSource);
			if (res) {
				setRefresh(!refresh);
				notification.success({
					message: "انتقال حذف شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	// function for editTransaction

	async function handleEditTransaction(transactionId) {
		const transactionToEdit = dataSource.find(
			(transaction) => transaction.key === transactionId
		);
		setSelectedTransaction(transactionToEdit);
		setIsEditing(true);
	}

	const handleUpdateTransaction = async (e) => {
		e.preventDefault();
		try {
			const billId = location.state.billId;
			const variables = {
				billId,
				transactionId: selectedTransaction.key,
				data: {
					date: selectedTransaction.date,
					branch: selectedTransaction.agyncy,
					followUpCode: selectedTransaction.followUpCode,
					account: selectedTransaction.account,
					amount: Number(selectedTransaction.amount),
				},
			};
			const res = await client.mutate({
				mutation: EDIT_TRANSACTION,
				variables: variables,
			});
			if (res.data.editTransaction) {
				setIsEditing(false);
				setRefresh(!refresh);
				notification.success({
					message: "انتقال با موفقیت به روز شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAdd = () => {
		setIsAdding(true);
		setNewTransaction((prevTransaction) => ({
			...prevTransaction,
			amount: "",
			followUpCode: "",
			date: today,
			branch: "",
			account: prevTransaction.account || "", // Preserve the account value
		}));
	};

	const resetAdding = () => {
		setIsAdding(false);
		setNewTransaction((prevTransaction) => ({
			...prevTransaction,
			amount: "",
			followUpCode: "",
			date: today,
			branch: "",
			account: prevTransaction.account || "", // Preserve the account value
		}));
	};
	return (
		<div className="px-4 space-y-4">
			{/* add bill */}
			<Modal
				title="افزودن فیش"
				width={420}
				className="custom-modal"
				open={isAdding}
				onCancel={resetAdding}
				footer={null}>
				<form onSubmit={handleCreateTransaction}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<div className="custom-date-picker">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									className="w-96"
									value={today}
									id="date"
									name="date"
								/>
							</div>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی گیرنده"
								className="w-96"
								name="account"
								value={newTransaction.account}
								onChange={(value) => {
									setNewTransaction((prevTransaction) => ({
										...prevTransaction,
										account: value, // Update only the account value
									}));
								}}>
								{accounts.map((account) => (
									<Select.Option key={account.id} value={account.id}>
										{account.accountName + " - " + account.accountNumber}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شبعه"
								name="branch"
								value={newTransaction.branch}
								onChange={(e) =>
									setNewTransaction({
										...newTransaction,
										branch: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="پیگیری"
								name="followUpCode"
								value={newTransaction.followUpCode}
								onChange={(e) => {
									const value = e.target.value;
									if (/^\d{0,6}$/.test(value)) {
										setNewTransaction({
											...newTransaction,
											followUpCode: value,
										});
									}
								}}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="مبلغ"
								name="amount"
								value={newTransaction.amount}
								onChange={(e) =>
									setNewTransaction({
										...newTransaction,
										amount: e.target.value,
									})
								}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							افزودن فیش
						</Button>
					</div>
				</form>
			</Modal>

			{/* edit bill */}
			<Modal
				title="ویرایش انتقال"
				open={isEditing}
				onCancel={() => setIsEditing(false)}
				width={420}
				footer={null}
				className="custom-modal">
				<form onSubmit={handleUpdateTransaction}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<div className="custom-date-picker">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									className="w-96"
									value={selectedTransaction?.date || today}
									id="date"
									name="date"
									onChange={(value) =>
										setSelectedTransaction((prev) => ({
											...prev,
											date: value.format("YYYY-MM-DD"),
										}))
									}
								/>
							</div>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="حساب"
								className="w-96"
								name="account"
								value={selectedTransaction?.account}
								onChange={(value) => {
									setSelectedTransaction((prevTransaction) => ({
										...prevTransaction,
										account: value,
									}));
								}}>
								{accounts.map((account) => (
									<Select.Option key={account.id} value={account.id}>
										{account.accountName + " - " + account.accountNumber}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شبعه"
								name="branch"
								value={selectedTransaction?.agyncy}
								onChange={(e) =>
									setSelectedTransaction((prev) => ({
										...prev,
										agyncy: e.target.value,
									}))
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="پیگیری"
								name="followUpCode"
								value={selectedTransaction?.followUpCode}
								onChange={(e) => {
									const value = e.target.value;
									if (/^\d{0,6}$/.test(value)) {
										setSelectedTransaction((prev) => ({
											...prev,
											followUpCode: value,
										}));
									}
								}}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="مبلغ"
								name="amount"
								value={selectedTransaction?.amount}
								onChange={(e) =>
									setSelectedTransaction((prev) => ({
										...prev,
										amount: e.target.value,
									}))
								}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							به روز رسانی
						</Button>
					</div>
				</form>
			</Modal>

			{/* UI */}
			<HeaderContent
				sender={sender}
				receiver={receiver}
				loading={loading}
				totalAmount={totalAmount}
				isShowSenderOrReceiver={true}
				previous={true}
				amount={true}
				download={true}
				print={false}
				handleAdd={handleAdd}
				search="جستجو کد پیگیری"
				onSearch={(value) => setSearching(value)}
			/>
			<TableComponent
				columns={columns}
				data={filteredData}
				loading={loading}
				pagination={{
					pageSize: 10,
					onChange: (current) => setPage(current),
					current: page,
				}}
			/>
		</div>
	);
}

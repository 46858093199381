import { gql } from "@apollo/client";

export const SIGN_IN = gql`
	mutation SignIn($data: SignInInput!) {
		signIn(data: $data) {
			accessToken
			refreshToken
			userInfo {
				id
				firstName
				lastName
				userName
				role
			}
			userType
		}
	}
`;
// customer api
export const DELETE_CUSTOMER = gql`
	mutation DeleteCustomer($customerId: String!) {
		deleteCustomer(customerId: $customerId) {
			id
		}
	}
`;
export const CREATE_CUSTOMER = gql`
	mutation createCustomer($data: CreateCustomerInput!) {
		createCustomer(data: $data) {
			id
			firstName
			lastName
			address
			phoneNumber
		}
	}
`;
export const UPDATE_CUSTOMER = gql`
	mutation EditCustomer($customerId: String!, $data: EditCustomerInput!) {
		editCustomer(customerId: $customerId, data: $data) {
			id
			firstName
			lastName
			address
			phoneNumber
		}
	}
`;
// user api
export const DELETE_USER = gql`
	mutation DeleteUser($userId: String!) {
		deleteUser(userId: $userId) {
			id
		}
	}
`;
export const CREATE_USER = gql`
	mutation createUser($data: CreateUserInput!) {
		createUser(data: $data) {
			id
			firstName
			lastName
			userName
			password
			role
		}
	}
`;
export const UPDATE_USER = gql`
	mutation editUser($userId: String!, $data: EditUserInput!) {
		editUser(userId: $userId, data: $data) {
			id
			firstName
			lastName
			userName
			password
			role
		}
	}
`;

// account api
export const DELETE_ACCOUNT = gql`
	mutation deleteAccount($accountId: String!) {
		deleteAccount(accountId: $accountId) {
			id
			accountName
			accountNumber
		}
	}
`;
export const CREATE_ACCOUNT = gql`
	mutation createAccount($data: CreateAccountInput!) {
		createAccount(data: $data) {
			id
			accountName
			accountNumber
		}
	}
`;
export const UPDATE_ACCOUNT = gql`
	mutation editAccount($accountId: String!, $data: EditAccountInput!) {
		editAccount(accountId: $accountId, data: $data) {
			id
			accountName
			accountNumber
		}
	}
`;

// bills api
export const DELETE_BILL = gql`
	mutation deleteBill($billId: ID!) {
		deleteBill(billId: $billId) {
			message
		}
	}
`;
export const CREATE_BILL = gql`
	mutation createBill($data: CreateBillInput!) {
		createBill(data: $data) {
			message
		}
	}
`;
export const UPDATE_BILL = gql`
	mutation editBill($billId: ID!, $data: EditBillInput!) {
		editBill(billId: $billId, data: $data) {
			message
		}
	}
`;
// transaction api

export const CREATE_TRANSACTION = gql`
	mutation CreateTransaction($billId: ID!, $data: CreateTransactionInput!) {
		createTransaction(billId: $billId, data: $data) {
			message
		}
	}
`;
export const DELETE_TRANSACTION = gql`
	mutation DeleteTransaction($billId: ID!, $transactionId: ID!) {
		deleteTransaction(billId: $billId, transactionId: $transactionId) {
			message
		}
	}
`;
export const EDIT_TRANSACTION = gql`
	mutation EditTransaction(
		$billId: ID!
		$transactionId: ID!
		$data: EditTransactionInput!
	) {
		editTransaction(
			billId: $billId
			transactionId: $transactionId
			data: $data
		) {
			message
		}
	}
`;

import React, { useEffect, useState } from "react";
import "../User/User.css";
import HeaderContent from "../../Components/HeaderContent/HeaderContent";
import TableComponent from "../../Components/Table/TableComponent";
import {
	Button,
	Input,
	Modal,
	Select,
	Space,
	Popconfirm,
	notification,
	Form,
} from "antd";

import { MdOutlineDeleteOutline, MdOutlineEdit, MdAdd } from "react-icons/md";
import { FaLock } from "react-icons/fa";

import { Option } from "antd/es/mentions";
import { useApolloClient } from "@apollo/client";
import { USERS } from "../../GraphQL/Query";
import { CREATE_USER, DELETE_USER, UPDATE_USER } from "../../GraphQL/Mutation";
import { render } from "@testing-library/react";

export default function User() {
	const client = useApolloClient();
	const [dataSource, setDataSource] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedRole, setSelectedRole] = useState("");
	const [selectedUser, setSelectedUser] = useState();
	const [filteredData, setFilteredData] = useState([]); // Add this state for filtered data
	const [userRole, setUserRole] = useState();
	useEffect(() => {
		loadUsers();
		const userInfo = localStorage.getItem("userInfo");
		setUserRole(JSON.parse(userInfo));
	}, [refresh]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام",
			dataIndex: "firstName",
			key: "firstName",
		},
		{
			title: "فامیل",
			dataIndex: "lastName",
			key: "lastName",
		},
		{
			title: "نام کاربری",
			dataIndex: "userName",
			key: "userName",
			render: (_, record) =>
				userRole?.role === "Admin" ? (
					record.userName
				) : (
					<Space>
						<FaLock />
						<FaLock />
						<FaLock />
					</Space>
				),
		},
		{
			title: "پسورد",
			dataIndex: "password",
			key: "password",
			render: (_, record) =>
				userRole?.role === "Admin" ? (
					record.password
				) : (
					<Space>
						<FaLock />
						<FaLock />
						<FaLock />
					</Space>
				),
		},
		{
			title: "صلاحیت",
			dataIndex: "role",
			key: "role",
		},
		{
			title: userRole?.role === "Admin" && "عملیات",
			key: "action",
			render: (_, record) =>
				userRole?.role === "Admin" && (
					<Space size="middle">
						<MdOutlineEdit
							color="blue"
							className="text-base cursor-pointer"
							onClick={() => handleEditUser(record?.id)}
						/>
						<Popconfirm
							title="آیا مطمین هستید میخواهید حذف نمایید؟"
							onConfirm={() => handleDeleteUser(record?.id)}
							okText="بله"
							cancelText="انصراف"
							placement="topLeft">
							<MdOutlineDeleteOutline
								className="text-base cursor-pointer"
								color="red"
							/>
						</Popconfirm>
					</Space>
				),
		},
	];
	const statusOptions = [
		{ label: "ادمین", value: "ADMIN" },
		{ label: "عادی", value: "STANDARD" },
	];
	// function for getUsers
	async function loadUsers(page = 1, searchItem = "") {
		setLoading(true);
		try {
			const res = await client.query({
				query: USERS,
				variables: {
					filter: { page, searchItem },
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setTotalCount(res.data.getUsers.totalCount);
				// Map over the data to add the `num` field
				const modifiedData = res.data.getUsers.users.map((item, index) => ({
					...item,
					num: (page - 1) * 10 + (index + 1), // Adjust index based on page number
					role: item.role === "Admin" ? "ادمین" : "عادی",
				}));
				setDataSource(modifiedData);
				setFilteredData(modifiedData); // Initialize filtered data
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for deleteUser
	async function handleDeleteUser(userId) {
		try {
			const res = await client.mutate({
				mutation: DELETE_USER,
				variables: {
					userId: userId,
				},
			});
			if (res) {
				setRefresh(!refresh);
				notification.success({
					message: "کاربر با موفقیت حذف شد",
					// description: "به سیستم خوش آمدید!",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for createUser
	async function handleCreateUser(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: CREATE_USER,
				variables: {
					data: {
						firstName: e.target.firstName.value,
						lastName: e.target.lastName.value,
						userName: e.target.userName.value,
						password: e.target.password.value,
						role: selectedRole,
					},
				},
			});
			if (res) {
				setIsAdding(!isAdding);
				setRefresh(!refresh);
				setSelectedRole("");
				notification.success({
					message: "کاربر جدید با موفقیت اضافه شد",
					// description: "به سیستم خوش آمدید!",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function fot editUser
	function handleEditUser(userId) {
		const filteredUser = dataSource.find((item) => item.id === userId);
		setSelectedUser(filteredUser);
		setIsEditing(true);
	}
	// function for updateUser
	async function handleUpdateUser(e) {
		e.preventDefault();
		const userId = selectedUser.id;
		try {
			const res = await client.mutate({
				mutation: UPDATE_USER,
				variables: {
					userId: userId,
					data: {
						firstName: e.target.name.value,
						lastName: e.target.lastName.value,
						userName: e.target.userName.value,
						password: e.target.password.value,
						role:
							selectedRole.role === ("ادمین" || "ADMIN")
								? "ADMIN"
								: selectedRole.role === ("STANDARD" || "عادی")
								? "STANDARD"
								: null,
					},
				},
			});
			if (res) {
				setIsEditing(!isEditing);
				setRefresh(!refresh);
				setSelectedRole("");
				notification.success({
					message: "کاربر با موفقیت ویرایش شد",
					// description: "به سیستم خوش آمدید!",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className="space-y-4">
			{/* Editing user */}
			<Modal
				title="ویرایش کاربر"
				className="custom-modal"
				width={420}
				open={isEditing}
				onCancel={() => setIsEditing(!isEditing)}
				footer={null}>
				<form onSubmit={handleUpdateUser}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام"
								id="name"
								name="name"
								value={selectedUser?.firstName}
								onChange={(e) =>
									setSelectedUser({
										...selectedUser,
										firstName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="فامیل"
								id="lastName"
								name="lastName"
								value={selectedUser?.lastName}
								onChange={(e) =>
									setSelectedUser({
										...selectedUser,
										lastName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام کاربری"
								id="userName"
								name="userName"
								value={selectedUser?.userName}
								onChange={(e) =>
									setSelectedUser({
										...selectedUser,
										userName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input.Password
								placeholder="پسورد"
								id="password"
								name="password"
								value={selectedUser?.password}
								onChange={(e) =>
									setSelectedUser({
										...selectedUser,
										password: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								className="w-full"
								name="role"
								value={selectedUser?.role}
								onChange={(value) =>
									setSelectedUser({
										...selectedUser,
										role: value, // Use the value directly here
									})
								}>
								{statusOptions.map((option) => (
									<Option
										key={option.value === "عادی" ? "STANDARD" : "ADMIN"}
										value={option.value === "عادی" ? "STANDARD" : "ADMIN"}>
										{option.label}
									</Option>
								))}
							</Select>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							ویرایش کاربر
						</Button>
					</div>
				</form>
			</Modal>

			{/* Adding User */}
			<Modal
				title="افزودن کاربر"
				className="custom-modal"
				width={420}
				open={isAdding}
				onCancel={() => setIsAdding(!isAdding)}
				footer={null}>
				<form onSubmit={handleCreateUser}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input placeholder="نام" id="firstName" name="firstName" />
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input placeholder="فامیل" id="lastName" name="lastName" />
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input placeholder="نام کاربری" id="userName" name="userName" />
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input.Password
								placeholder="پسورد"
								id="password"
								name="password"
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								className="w-full"
								placeholder="صلاحیت"
								onChange={(value) => {
									setSelectedRole(value);
								}}>
								{statusOptions.map((option) => (
									<Option key={option.value} value={option.value}>
										{option.label}
									</Option>
								))}
							</Select>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							افزودن کاربر
						</Button>
					</div>
				</form>
			</Modal>

			{/* UI */}
			<HeaderContent
				handleAdd={() => setIsAdding(true)}
				onSearch={(value) => loadUsers(page, value)}
				search="جستجو کاربران"
			/>
			<TableComponent
				columns={columns}
				data={filteredData}
				loading={loading}
				pagination={{
					pageSize: 10, // Number of records per page
					total: totalCount,
					onChange: (current) => {
						setPage(current); // Update page state
						loadUsers(current); // Fetch the new page data
					},
					current: page, // Track the current page
					showLessItems: true, // Optional: Keep pagination UI minimal
				}}
			/>
		</div>
	);
}

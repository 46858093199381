import React, { useEffect, useState } from "react";
import "../Customer/Customer.css";
import HeaderContent from "../../Components/HeaderContent/HeaderContent";
import { Input, Modal, Space, Popconfirm, notification, Button } from "antd";
import { MdOutlineDeleteOutline, MdOutlineEdit, MdAdd } from "react-icons/md";
import TableComponent from "../../Components/Table/TableComponent";
import { useApolloClient } from "@apollo/client";
import { ACCOUNTS } from "../../GraphQL/Query";
import {
	CREATE_ACCOUNT,
	DELETE_ACCOUNT,
	UPDATE_ACCOUNT,
} from "../../GraphQL/Mutation";

export default function Accounts() {
	const client = useApolloClient();
	const [dataSource, setDataSource] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [editingRow, setEditingRow] = useState({});
	const [isAdding, setIsAdding] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [filteredData, setFilteredData] = useState([]); // Add this state for filtered data
	const [selectedAccount, setSelectedAccount] = useState();
	const [userRole, setUserRole] = useState();
	useEffect(() => {
		loadAccounts();
		const userInfo = localStorage.getItem("userInfo");
		setUserRole(JSON.parse(userInfo));
	}, [refresh]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
		},
		{
			title: "نام حساب",
			dataIndex: "accountName",
			key: "accountName",
		},
		{
			title: "شماره حساب",
			dataIndex: "accountNumber",
			key: "accountNumber",
		},
		{
			title: userRole?.role === "Admin" && "عملیات",
			key: "action",
			render: (_, record) =>
				userRole?.role === "Admin" && (
					<Space size="middle">
						<MdOutlineEdit
							color="blue"
							className="text-base cursor-pointer"
							onClick={() => handleEditAccount(record.id)}
						/>
						<Popconfirm
							title="آیا مطمین هستید میخواهید حذف نمایید؟"
							onConfirm={() => handleDeleteAccount(record?.id)}
							okText="بله"
							cancelText="انصراف"
							placement="topLeft">
							<MdOutlineDeleteOutline
								className="text-base cursor-pointer"
								color="red"
							/>
						</Popconfirm>
					</Space>
				),
		},
	];

	const onEditingRow = (record) => {
		setIsEditing(true);
		setEditingRow({ ...record });
	};
	const resetEditing = () => {
		setIsEditing(false);
		setEditingRow({});
	};
	const handleAdd = () => {
		setIsAdding(true);
		setEditingRow({
			key: dataSource.length + 1,
			num: dataSource.length + 1,
			fullName: "",
			accountName: "",
			accountNumber: "",
		});
	};
	const resetAdding = () => {
		setIsAdding(false);
		setEditingRow({
			fullName: "",
			accountName: "",
			accountNumber: "",
		});
	};
	// function for getAccounts
	async function loadAccounts(page = 1, searchItem = "") {
		setLoading(true);
		try {
			const res = await client.query({
				query: ACCOUNTS,
				variables: {
					filter: { page, searchItem },
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				// Map over the data to add the `num` field
				setTotalCount(res.data.getAccounts.totalCount);
				const modifiedData = res.data.getAccounts.accounts.map(
					(item, index) => ({
						...item,
						num: (page - 1) * 10 + (index + 1), // Adjust index based on page number
					})
				);
				setDataSource(modifiedData);
				setFilteredData(modifiedData);
				setLoading(false);
			}
		} catch (error) {}
	}
	// function for deleteAccount
	async function handleDeleteAccount(accountId) {
		try {
			const res = await client.mutate({
				mutation: DELETE_ACCOUNT,
				variables: {
					accountId: accountId,
				},
			});
			if (res) {
				setRefresh(!refresh);
				notification.success({
					message: "حساب با موفقیت حذف شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for createAccount
	async function handleCreateAccount(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: CREATE_ACCOUNT,
				variables: {
					data: {
						accountName: e.target.accountName.value,
						accountNumber: e.target.accountNumber.value,
					},
				},
			});
			if (res) {
				setIsAdding(!isAdding);
				setRefresh(!refresh);
				notification.success({
					message: "حساب جدید با موفقیت اضافه شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for editAccount
	function handleEditAccount(accountId) {
		const filteredAccount = dataSource.filter((item) => item.id === accountId);
		setSelectedAccount(filteredAccount[0]);
		setIsEditing(!isEditing);
	}
	// function for updateAccount
	async function handleUpdateAccount(e) {
		const accountId = selectedAccount.id;
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: UPDATE_ACCOUNT,
				variables: {
					accountId: accountId,
					data: {
						accountName: e.target.accountName.value,
						accountNumber: e.target.accountNumber.value,
					},
				},
			});
			if (res) {
				setIsEditing(!isEditing);
				setRefresh(!refresh);
				notification.success({
					message: "حساب با موفقیت ویرایش گردید",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className="px-4 space-y-4">
			{/* Adding Account */}
			<Modal
				title="افزودن حساب"
				open={isAdding}
				onCancel={() => setIsAdding(!isAdding)}
				width={420}
				className="custom-modal"
				footer={null}>
				<form onSubmit={handleCreateAccount}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام حساب"
								id="accountName"
								name="accountName"
								className="w-96"
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شماره حساب"
								id="accountNumber"
								name="accountNumber"
								className="w-96"
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							افزودن حساب
						</Button>
					</div>
				</form>
			</Modal>
			{/* Editing Account */}
			<Modal
				title="ویرایش حساب"
				open={isEditing}
				onCancel={() => setIsEditing(!isEditing)}
				width={420}
				className="custom-modal"
				footer={null}>
				<form onSubmit={handleUpdateAccount}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام حساب"
								id="accountName"
								name="accountName"
								className="w-96"
								value={selectedAccount?.accountName}
								onChange={(e) =>
									setSelectedAccount({
										...selectedAccount,
										accountName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شبا حساب"
								id="accountNumber"
								name="accountNumber"
								className="w-96"
								value={selectedAccount?.accountNumber}
								onChange={(e) =>
									setSelectedAccount({
										...selectedAccount,
										accountNumber: e.target.value,
									})
								}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							ویرایش حساب
						</Button>
					</div>
				</form>
			</Modal>
			<HeaderContent
				handleAdd={handleAdd}
				search="جستجو حساب"
				onSearch={(value) => loadAccounts(page, value)}
			/>
			<TableComponent
				columns={columns}
				data={filteredData}
				loading={loading}
				pagination={{
					pageSize: 10, // Number of records per page
					total: totalCount,
					onChange: (current) => {
						setPage(current); // Update page state
						loadAccounts(current); // Fetch the new page data
					},
					current: page, // Track the current page
					showLessItems: true, // Optional: Keep pagination UI minimal
				}}
			/>
		</div>
	);
}

import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
} from "@react-pdf/renderer";

import font from "../../assets/IranSans.ttf";
// Create styles
const persianText = "متن فارسی";
const encodedText = encodeURIComponent(persianText);
<Text>{decodeURIComponent(encodedText)}</Text>;
Font.register({
	family: "IRANSans",
	format: "truetype",
	src: font,
});
// Create styles
const styles = StyleSheet.create({
	page: {
		padding: 20,
		fontFamily: "IRANSans",
		fontSize: 12,
	},
	header: {
		fontSize: 14,
		marginBottom: 10,
		textAlign: "center",
		fontWeight: "bold",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 10,
	},
	headerTitle: {
		fontSize: 15,
		marginBottom: 10,
		textAlign: "center",
		fontWeight: "bold",
	},
	headerLine: {
		width: "15%",
		height: 2,
		backgroundColor: "black",
	},
	headerContent: {
		flexDirection: "row-reverse",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
	},
	headerInformation: {
		flexDirection: "column",
		alignItems: "flex-end",
		gap: 10,
	},
	headerInformationRow: {
		flexDirection: "row",
		justifyContent: "flex-start",
		textAlign: "right",
	},
	footer: {
		position: "absolute",
		fontSize: 10,
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: "center",
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
	},
	tableRow: {
		flexDirection: "row-reverse",
	},
	tableColHeader: {
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		backgroundColor: "#f3f3f3",
		padding: 5,
		textAlign: "center",
		fontWeight: "bold",
	},
	tableCol: {
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#bdbdbd",
		padding: 5,
		textAlign: "center",
	},
	tableCell: {
		margin: 5,
		fontSize: 10,
	},
});
const Header = ({ data }) => (
	<View style={styles.header}>
		<Text style={styles.headerTitle}>سیستم حسابداری فیشها</Text>
		<Text style={styles.headerLine}></Text>
		<View style={styles.headerContent}>
			<View style={styles.headerInformation}>
				<View style={styles.headerInformationRow}>
					<Text>{data[0]?.col7}</Text>
					<Text> : </Text>
					<Text>واریزکننده</Text>
				</View>
				<View style={styles.headerInformationRow}>
					<Text>{data[0]?.col8}</Text>
					<Text> : </Text>
					<Text>گیرنده</Text>
				</View>
			</View>
			<View style={styles.headerInformation}>
				<View style={styles.headerInformationRow}>
					<Text>{data[0]?.col9}</Text>
					<Text> : </Text>
					<Text>تاریخ</Text>
				</View>
				<View style={styles.headerInformationRow}>
					<Text>{" تومان "}</Text>
					<Text>{data[0]?.col10}</Text>
					<Text> : </Text>
					<Text>مبلغ</Text>
				</View>
			</View>
		</View>
	</View>
);
const Footer = ({ pageNumber }) => (
	<View style={styles.footer}>
		<Text>Page {pageNumber}</Text>
	</View>
);
const Table = ({ data }) => (
	<View style={styles.table}>
		{/* Table Header */}
		<View style={styles.tableRow}>
			<Text style={[styles.tableColHeader, { width: "10%" }]}>#</Text>
			<Text style={[styles.tableColHeader, { width: "40%" }]}>حساب</Text>
			<Text style={[styles.tableColHeader, { width: "25%" }]}>شبا</Text>
			<Text style={[styles.tableColHeader, { width: "20%" }]}>مبلغ</Text>
			<Text style={[styles.tableColHeader, { width: "12%" }]}>شعبه</Text>
			<Text style={[styles.tableColHeader, { width: "12%" }]}>پیگیری</Text>
		</View>
		{/* Table Rows */}
		{data.map((row, index) => (
			<View key={index} style={styles.tableRow}>
				<Text style={[styles.tableCol, { width: "10%" }]}>{row.col1}</Text>
				<Text style={[styles.tableCol, { width: "40%" }]}>{row.col2}</Text>
				<Text style={[styles.tableCol, { width: "25%" }]}>{row.col3}</Text>
				<Text style={[styles.tableCol, { width: "20%" }]}>{row.col4}</Text>
				<Text style={[styles.tableCol, { width: "12%" }]}>{row.col5}</Text>
				<Text style={[styles.tableCol, { width: "12%" }]}>{row.col6}</Text>
			</View>
		))}
	</View>
);

function PdfComponent({ data }) {
	return (
		<Document>
			{data.map((pageData, index) => (
				<Page size="A4" key={index} style={styles.page} orientation="landscape">
					<Header data={pageData} />
					<Table data={pageData} />
					<Footer pageNumber={index + 1} />
				</Page>
			))}
		</Document>
	);
}

export default PdfComponent;

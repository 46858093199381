import React from "react";
import { useLocation } from "react-router-dom";
import AppRoute from "../AppRoute/AppRoute";
import "../PageContent/PageContent.css";

export default function PageContent() {
  const location = useLocation();

  const isLoginRoute = location.pathname === "/login";

  return (
    <div
      className={`${
        isLoginRoute ? "w-full" : "lg:w-[85%] lg:mr-[15%] lg:fixed w-full xl:h-screen p-5 "
      } bg-contentBg `}
    >
      <AppRoute />
    </div>
  );
}

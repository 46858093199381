import React, { useEffect, useState } from "react";
import "../HeaderContent/HeaderContent.css";
import { Input, Button, Space, Tag, Spin } from "antd";
import { MdAdd } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import { IoIosPrint } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import PdfComponent from "../Pdf/PdfComponent";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
import { GET_BILL } from "../../GraphQL/Query";

const { Search } = Input;

// const onSearch = (value, _e, info) => console.log(info?.source, value);
export default function HeaderContent({ handleAdd, onSearch, ...props }) {
	const navigate = useNavigate();
	const location = useLocation();

	const [data, setData] = useState();
	const [selectedId, setSelectedId] = useState(localStorage.getItem("billId"));
	const [userRole, setUserRole] = useState();

	const client = useApolloClient();

	const handleSearchChange = (e) => {
		const value = e.target.value;
		onSearch(value); // Pass the search value to the parent component
	};

	useEffect(() => {
		const userInfo = localStorage.getItem("userInfo");
		setUserRole(JSON.parse(userInfo));
		if (selectedId) {
			setSelectedId(localStorage.getItem("billId"));
		}
		loadTransactions();
	}, []);
	async function loadTransactions() {
		try {
			const res = await client.query({
				query: GET_BILL,
				variables: { billId: localStorage.getItem("billId") },
				fetchPolicy: "no-cache",
			});
			if (res) {
				const sender = `${res.data.getBill.sender.firstName} ${res.data.getBill.sender.lastName}`;
				const receiver = `${res.data.getBill.reciever.firstName} ${res.data.getBill.reciever.lastName}`;
				// Calculate the total sum of col4 (transaction.amount)
				const total = res.data.getBill.transactions.reduce((sum, item) => {
					const amount = parseFloat(item.amount); // Ensure amount is a number
					return sum + (isNaN(amount) ? 0 : amount); // Add to sum if valid, otherwise add 0
				}, 0);

				const modifiedData = res.data.getBill.transactions.map(
					(transaction, index) => ({
						col1: index + 1,
						col2: transaction.account?.accountName,
						col3: transaction.account?.accountNumber,
						col4: parseFloat(transaction.amount),
						col5: transaction.branch,
						col6: transaction.followUpCode,
						col7: sender,
						col8: receiver,
						col9: transaction.date,
						col10: total,
					})
				);
				setData(modifiedData);
			}
		} catch (error) {
			console.error("Error fetching bill data:", error);
		}
	}

	function splitDataIntoPages(data, rowsPerPage) {
		const pages = [];
		for (let i = 0; i < data?.length; i += rowsPerPage) {
			pages.push(data.slice(i, i + rowsPerPage));
		}
		return pages;
	}

	// Example usage:
	const rowsPerPage = 11;
	const pages = splitDataIntoPages(data, rowsPerPage);
	return (
		<div className="w-full p-2 rounded-lg mt-5 shadow-md flex xl:flex-row flex-col justify-start xl:items-center gap-2.5 bg-white">
			{props.previous === true ? (
				<FaArrowCircleRight
					className="mr-5 hover:cursor-pointer hover:text-blue-400"
					onClick={() => {
						navigate(-1);
					}}
				/>
			) : null}
			<Search
				placeholder={props.search}
				size="large"
				onChange={handleSearchChange}
				enterButton
				className="rtl xl:w-64 search-placeholder"
			/>
			{location.pathname === "/user" && userRole?.role === "Admin" ? (
				<Button
					type="primary"
					icon={<MdAdd />}
					size="large"
					onClick={handleAdd}
					className="font-medium">
					افزودن
				</Button>
			) : (
				location.pathname !== "/user" && (
					<Button
						type="primary"
						icon={<MdAdd />}
						size="large"
						onClick={handleAdd}
						className="font-medium">
						افزودن
					</Button>
				)
			)}
			{props.print === false ? null : (
				<Button
					type="primary"
					icon={<IoIosPrint />}
					size="large"
					className="font-medium">
					چاپ
				</Button>
			)}
			{props.download === true ? (
				<PDFDownloadLink
					document={<PdfComponent data={pages} />}
					fileName="Fesh">
					{({ loading }) =>
						loading ? (
							<Spin />
						) : (
							<Button
								type="primary"
								icon={<FaFilePdf />}
								size="large"
								className="w-full font-medium">
								دانلود
							</Button>
						)
					}
				</PDFDownloadLink>
			) : null}
			{/* <PDFViewer width="100%" height="600">
				<PdfComponent data={pages} />
			</PDFViewer> */}
			{props.amount === true ? (
				<Space>
					<Tag color="success" className="p-2 text-base">{`کل واریزی ${
						props?.totalAmount
					}${" "}تومان`}</Tag>
				</Space>
			) : null}

			{props.isShowSenderOrReceiver && (
				<div>
					<div className="flex items-center gap-2">
						<h1 className="text-lg font-extrabold text-center w-14">
							فرستنده :
						</h1>
						{props.loading ? (
							<Spin />
						) : (
							<h3 className="text-base font-semibold">{props.sender}</h3>
						)}
					</div>
					<div className="flex items-center gap-2">
						<h1 className="text-lg font-extrabold text-end w-14">گیرنده :</h1>
						{props.loading ? (
							<Spin />
						) : (
							<p className="text-base font-semibold">{props.receiver}</p>
						)}
					</div>
				</div>
			)}
			{}
		</div>
	);
}

import { gql } from "@apollo/client";

export const USERS = gql`
	query getUsers($filter: UsersWhereInput!) {
		getUsers(filter: $filter) {
			users {
				id
				firstName
				lastName
				userName
				password
				role
			}
			totalCount
		}
	}
`;
export const CUSTOMERS = gql`
	query GetCustomers($filter: CustomersWhereInput!) {
		getCustomers(filter: $filter) {
			customers {
				id
				firstName
				lastName
				address
				phoneNumber
			}
			totalCount
		}
	}
`;
export const ACCOUNTS = gql`
	query getAccounts($filter: AccountsWhereInput!) {
		getAccounts(filter: $filter) {
			accounts {
				id
				accountName
				accountNumber
			}
			totalCount
		}
	}
`;
export const BILLS = gql`
	query getBills($filter: BillsWhereInput!) {
		getBills(filter: $filter) {
			bills {
				id
				sender {
					id
					firstName
					lastName
					address
					phoneNumber
				}
				reciever {
					id
					firstName
					lastName
					address
					phoneNumber
				}
				totalAmount
				date
				status
			}
			totalCount
		}
	}
`;
export const STATISTIC = gql`
	query {
		getStatistic {
			billsCount
			customersCount
			usersCount
			accountsCount
		}
	}
`;
export const GET_BILL = gql`
	query GetBill($billId: ID!) {
		getBill(billId: $billId) {
			id
			sender {
				id
				firstName
				lastName
				address
				phoneNumber
			}
			reciever {
				id
				firstName
				lastName
				address
				phoneNumber
			}
			transactions {
				id

				account {
					id
					accountName
					accountNumber
				}
				amount
				date
				branch
				followUpCode
			}
		}
	}
`;
//graph statistic

export const GET_GRAPH_STATISTIC = gql`
	query GetGraphStatistic($year: Float!) {
		getGraphStatistic(year: $year) {
			month
			billsCount
			customersCount
			accountsCount
		}
	}
`;

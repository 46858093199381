import React, { useEffect, useState } from "react";
import "../Fesh/Fesh.css";
import HeaderContent from "../../Components/HeaderContent/HeaderContent";
import {
	Space,
	Tag,
	Modal,
	Input,
	notification,
	Popconfirm,
	Button,
	Select,
} from "antd";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import {
	MdOutlineDeleteOutline,
	MdOutlineEdit,
	MdOutlineMoreVert,
	MdAdd,
} from "react-icons/md";
import TableComponent from "../../Components/Table/TableComponent";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { BILLS, CUSTOMERS, GET_BILL } from "../../GraphQL/Query";
import { CREATE_BILL, DELETE_BILL, UPDATE_BILL } from "../../GraphQL/Mutation";
import { formatNumberWithCommas } from "../../utils/formatNumberWithCommas";

const { Option } = Select;
export default function Fesh() {
	const date = new DateObject({
		calendar: persian,
		locale: persian_fa,
		format: "YYYY-MM-DD",
	});
	const today = date.format();
	const navigate = useNavigate();
	const client = useApolloClient();
	const [dataSource, setDataSource] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [search, setSearching] = useState("");
	const [selectedBill, setSelectedBill] = useState({});
	const [filteredData, setFilteredData] = useState([]); // Add this state for filtered data
	const [newBill, setNewBill] = useState({
		sender: "",
		reciever: "",
		totalAmount: "",
		date: "",
	});
	const MONTHS = [
		"حمل",
		"ثور",
		"جوزا",
		"سرطان",
		"اسد",
		"سنبله",
		"میزان",
		"عقرب",
		"قوس",
		"جدی",
		"دلو",
		"حوت",
	];
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
			// render: (text) => <a>{text}</a>,
		},
		{
			title: "تاریخ",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "فرستنده",
			dataIndex: "sender",
			key: "sender",
			responsive: ["md"],
			render: (item) => item.firstName + " " + item.lastName,
		},
		{
			title: "گیرنده",
			dataIndex: "reciever",
			key: "reciever",
			render: (item) => item.firstName + " " + item.lastName,
		},
		{
			title: "مبلغ",
			dataIndex: "totalAmount",
			key: "totalAmount",
			render: (amount) => formatNumberWithCommas(amount),
		},
		{
			title: "وضیعت",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<Tag
					color={
						status === "Pending"
							? "warning"
							: status === "Accepted"
							? "success"
							: "error"
					}>
					{status === "Pending"
						? "در حال بررسی"
						: status === "Accepted"
						? "تکمیل"
						: "تکرار"}
				</Tag>
			),
		},
		{
			title: "عملیات",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<MdOutlineMoreVert
						className="text-base cursor-pointer"
						onClick={() => {
							localStorage.setItem("billId", record?.id);
							navigate("/feshDetails", {
								state: { billId: record?.id },
							});
						}}
					/>
					<MdOutlineEdit
						color="blue"
						className="text-base cursor-pointer"
						onClick={() => handleEditBill(record?.id)}
					/>
					<Popconfirm
						title="آیا مطمین هستید میخواهید حذف نمایید؟"
						onConfirm={() => handleDeleteBill(record?.id)}
						okText="بله"
						cancelText="انصراف"
						placement="topLeft">
						<MdOutlineDeleteOutline
							className="text-base cursor-pointer"
							color="red"
						/>
					</Popconfirm>
				</Space>
			),
		},
	];
	const handleAdd = () => {
		setIsAdding(true);
		setNewBill({
			sender: "",
			reciever: "",
			totalAmount: "",
			date: "",
		});
	};
	const resetAdding = () => {
		setIsAdding(false);
		setNewBill({
			sender: "",
			reciever: "",
			totalAmount: "",
			date: "",
		});
	};
	useEffect(() => {
		loadBills();
		loadCustomer();
	}, [refresh]);

	// function for getCustomer
	async function loadCustomer(page = 1, searchItem = "") {
		setLoading(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					filter: { page, searchItem },
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setCustomers(res.data.getCustomers.customers);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	// Function to fetch bills
	async function loadBills(page = 1, searchItem = "") {
		setLoading(true);
		try {
			const res = await client.query({
				query: BILLS,
				variables: {
					filter: { page, searchItem }, // Pass the current page
				},
				fetchPolicy: "no-cache",
			});

			if (res) {
				const billsData = res.data.getBills.bills;
				setTotalCount(res.data.getBills.totalCount);

				// Fetch transactions for each bill and compare amounts
				const modifiedData = await Promise.all(
					billsData.map(async (item, index) => {
						const transactionsRes = await client.query({
							query: GET_BILL,
							variables: {
								billId: item.id,
							},
							fetchPolicy: "no-cache",
						});

						const transactions = transactionsRes.data.getBill.transactions;
						const totalTransactionAmount = transactions.reduce(
							(acc, transaction) => acc + transaction.amount,
							0
						);

						// Determine the status based on the amounts
						const isAccepted = totalTransactionAmount === item.totalAmount;
						return {
							...item,
							num: (page - 1) * 10 + (index + 1), // Adjust index based on page number
							status: isAccepted ? "Accepted" : "Pending",
						};
					})
				);

				// Replace previous data with the new page data
				setDataSource(modifiedData);
				setFilteredData(modifiedData); // Replace data for the current page
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}
	// function for deleteBill
	async function handleDeleteBill(billId) {
		try {
			const res = await client.mutate({
				mutation: DELETE_BILL,
				variables: {
					billId: billId,
				},
			});
			if (res) {
				setRefresh(!refresh);
				notification.success({
					message: "مشتری با موفقیت حذف شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for createBill
	async function handleCreateBill(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: CREATE_BILL,
				variables: {
					data: {
						sender: newBill.sender,
						reciever: newBill.reciever,
						totalAmount: Number(e.target.totalAmount.value),
						date: e.target.date.value,
						status: "PENDING",
					},
				},
			});
			if (res) {
				setIsAdding(!isAdding);
				setRefresh(!refresh);
				notification.success({
					message: "فیش جدید با موفقیت اضافه شد",
					placement: "topRight",
					duration: 3,
				});
				resetAdding();
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function fot editBill
	function handleEditBill(billId) {
		const filteredBill = dataSource.find((item) => item.id === billId);
		setSelectedBill(filteredBill);
		setIsEditing(true);
	}
	// function for updateBill
	async function handleUpdateBill(e) {
		e.preventDefault();

		const billId = selectedBill.id;
		try {
			const res = await client.mutate({
				mutation: UPDATE_BILL,
				variables: {
					billId,
					data: {
						sender: selectedBill.sender.id,
						reciever: selectedBill.reciever.id,
						totalAmount: Number(e.target.totalAmount.value),
						date: e.target.date.value,
						status: "PENDING",
					},
				},
			});
			if (res) {
				setIsEditing(!isEditing);
				setRefresh(!refresh);
				notification.success({
					message: "فیش با موفقیت ویرایش شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className="px-4 space-y-4">
			{/* edit bill */}
			<Modal
				title="ویرایش فیش"
				width={420}
				className="custom-modal"
				open={isEditing}
				onCancel={() => {
					setIsEditing(!isEditing);
				}}
				footer={null}>
				<form onSubmit={handleUpdateBill}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<div className="custom-date-picker">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									className="w-96"
									value={selectedBill?.date}
									id="date"
									name="date"
								/>
							</div>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی فرستنده"
								className="w-96"
								name="sender"
								defaultValue={selectedBill?.sender?.id}
								onSearch={(value) => loadCustomer(page, value)}>
								{customers.map((customer) => (
									<Option
										key={selectedBill?.sender?.id}
										value={selectedBill?.sender?.id}>
										{customer.firstName + "  " + customer.lastName}
									</Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی گیرنده"
								className="w-96"
								name="reciever"
								defaultValue={selectedBill?.reciever?.id}
								onSearch={(value) => loadCustomer(page, value)}>
								{customers.map((customer) => (
									<Option
										key={selectedBill?.reciever?.id}
										value={selectedBill?.reciever?.id}>
										{customer.firstName + "  " + customer.lastName}
									</Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="مبلغ"
								id="totalAmount"
								name="totalAmount"
								value={selectedBill?.totalAmount || ""}
								onChange={(e) =>
									setSelectedBill({
										...selectedBill,
										totalAmount: e.target.value,
									})
								}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							ویرایش فیش
						</Button>
					</div>
				</form>
			</Modal>
			{/* add bill */}
			<Modal
				title="افزودن فیش"
				width={420}
				className="custom-modal"
				open={isAdding}
				onCancel={resetAdding}
				footer={null}>
				<form onSubmit={handleCreateBill}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<div className="custom-date-picker">
								<DatePicker
									calendar={persian}
									locale={persian_fa}
									months={MONTHS}
									calendarPosition="top-right"
									format="YYYY-MM-DD"
									className="w-96"
									value={today}
									id="date"
									name="date"
								/>
							</div>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی فرستنده"
								className="w-96"
								name="sender"
								onSearch={(value) => loadCustomer(page, value)}>
								{customers.map((customer) => (
									<Option key={customer.id} value={customer.id}>
										{customer.firstName + " - " + customer.lastName}
									</Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="جستجوی گیرنده"
								className="w-96"
								name="reciever"
								onSearch={(value) => loadCustomer(page, value)}>
								{customers.map((customer) => (
									<Option key={customer.id} value={customer.id}>
										{customer.firstName + " - " + customer.lastName}
									</Option>
								))}
							</Select>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="مبلغ"
								id="totalAmount"
								name="totalAmount"
								value={newBill.totalAmount}
								onChange={(e) =>
									setNewBill({ ...newBill, totalAmount: e.target.value })
								}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							افزودن فیش
						</Button>
					</div>
				</form>
			</Modal>
			<HeaderContent
				handleAdd={handleAdd}
				search="جستجو فرستنده| گیرنده"
				onSearch={(value) => loadBills(page, value)}
			/>
			<TableComponent
				columns={columns}
				data={filteredData}
				loading={loading}
				pagination={{
					pageSize: 10, // Number of records per page
					total: totalCount,
					onChange: (current) => {
						setPage(current); // Update page state
						loadBills(current); // Fetch the new page data
					},
					current: page, // Track the current page
					showLessItems: true, // Optional: Keep pagination UI minimal
				}}
			/>
		</div>
	);
}

import React from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaMoneyCheckAlt, FaCreditCard, FaUsers } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { RiLogoutCircleRFill } from "react-icons/ri";

const MobileSidebar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (item) => {
    navigate(item.key);
    toggleSidebar();
  };
  return (
    <div
      className="border-l border-l-gray-300 lg:hidden black font-semibold"
      style={{ fontFamily: "BNazanin" }}
    >
      <Menu
        className="text-lg"
        onClick={handleClick}
        selectedKeys={[location.pathname]}
        items={[
          {
            label: "خانه",
            icon: <FaHome size={16} />,
            key: "/",
          },
          {
            label: "فیش‌ها",
            icon: <FaMoneyCheckAlt size={16} />,
            key: "/fesh",
          },
          {
            label: "مشتریان",
            icon: <IoIosPeople size={16} />,
            key: "/customer",
          },
          {
            label: "حسابات",
            icon: <FaCreditCard size={16} />,
            key: "/account",
          },
          {
            label: "کاربران",
            icon: <FaUsers size={16} />,
            key: "/user",
          },
          {
            type: "divider",
            style: { color: "#d1d5db" },
          },
          {
            label: "خروج",
            icon: <RiLogoutCircleRFill size={16} />,
            key: "/log-out",
            style: { marginTop: "40px" },
          },
        ]}
      ></Menu>
    </div>
  );
};

export default MobileSidebar;

import React from "react";
import "../Table/Table.css";
import { Table } from "antd";
// import { createStyles } from "antd-style";
// const useStyle = createStyles(({ css, token }) => {
// 	const { antCls } = token;
// 	return {
// 		customTable: css`
// 			${antCls}-table {
// 				${antCls}-table-container {
// 					${antCls}-table-body,
// 					${antCls}-table-content {
// 						scrollbar-width: thin;
// 						scrollbar-color: unset;
// 					}
// 				}
// 			}
// 		`,
// 	};
// });
const TableComponent = (props) => {
	// const { styles } = useStyle();
	const columns = props.columns;
	const data = props.data;
	return (
		<div className="w-full overflow-x-auto table-container">
			<Table
				columns={columns}
				dataSource={data}
				pagination={props.pagination}
				loading={props.loading}
				className={`min-w-[900px] font-semibold `}
				locale={{
					emptyText: (
						<img
							src="emptyData.png"
							alt="empty"
							width={200}
							style={{ margin: "0 auto" }}
						/>
					),
				}}
				scroll={{
					x: 600,
					y: 55 * 5,
				}}
			/>
		</div>
	);
};
export default TableComponent;

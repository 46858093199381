import React from "react";
import "../Home/Home.css";
import HomePage from "../../Components/HomePage/HomePage";
export default function Home() {
  return (
    <div className="home">
      <HomePage/>
    </div>
  );
}

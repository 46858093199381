import React from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar/SideBar";
import Header from "./Components/Header/Header";
import PageContent from "./Components/PageContent/PageContent";
import "./App.css";

function App() {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  return (
    <div className="">
      {!isLoginPage && <Header />}
      <div className="flex w-full">
        {!isLoginPage && <SideBar />}
        <PageContent />
      </div>
    </div>
  );
}

export default App;

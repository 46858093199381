import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import User from "../../Pages/User/User";
import Customer from "../../Pages/Customer/Customer";
import Fesh from "../../Pages/Fesh/Fesh";
import Login from "../Login/Login";
import Accounts from "../../Pages/Accounts/Accounts";
import FeshDetails from "../../Pages/Fesh/FeshDetails";
import ProtectedRoute from "../../utils/ProtectedRoute"; // Import ProtectedRoute
import AboutUs from "../../Pages/about-us/AboutUs";

export default function AppRoute() {
  return (
    <div className="appRoute-container">
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<User />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/fesh" element={<Fesh />} />
          <Route path="/feshDetails" element={<FeshDetails />} />
          <Route path="/account" element={<Accounts />} />
          <Route path="/about-us" element={<AboutUs/>} />
          {/* <Route path="/about" element={<AboutUs />} /> */}
          
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Form, Input, Typography, Spin, notification } from "antd";
import "../Login/Login.css";
import { useMutation } from "@apollo/client";
import { SIGN_IN } from "../../GraphQL/Mutation";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const onFinishFailed = (errorInfo) => {
	console.log("Failed:", errorInfo);
};

export default function Login() {
	const navigate = useNavigate();
	const [signIn, { loading }] = useMutation(SIGN_IN);

	const onFinish = async (values) => {
		if (!values.username || !values.password) {
			console.error("Username or password is missing.");
			return;
		}

		try {
			const response = await signIn({
				variables: {
					data: {
						userName: values.username,
						password: values.password,
					},
				},
			});

      if (response.data) {
        const { accessToken, refreshToken, userInfo } = response.data.signIn;
        // Store the tokens in local storage
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        // Show success notification
        notification.success({
          message: "ورود با موفقیت انجام شد",
          description: "به سیستم خوش آمدید!",
          placement: "topRight",
          duration: 3,
        });

        // Redirect to the home page
        navigate("/");
      }
    } catch (err) {
      notification.error({
        message: "خطا در ورود",
        description: "نام کاربری یا پسورد اشتباه است.",
        placement: "topRight",
        duration: 3,
      });
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <Spin spinning={loading}>
        <div className="w-full max-w-md p-6 rounded-lg shadow-md bg-bgLogin">
          <div className="mb-10">
            <Title
              level={1}
              className="text-xl font-semibold text-center text-textColor"
            >
              سیستم فیش های صرافی
            </Title>
          </div>

          <Form
            name="basic"
            className="space-y-4"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="نام کاربری"
              name="username"
              rules={[
                {
                  required: true,
                  message: "لطفا نام کاربری تان را وارد نمایید!",
                },
              ]}
              className="font-medium"
            >
              <Input placeholder="نام کاربری" />
            </Form.Item>

						<Form.Item
							label="پسورد"
							name="password"
							rules={[
								{
									required: true,
									message: "لطفا پسورد تان را وارد نمایید!",
								},
							]}
							className="font-medium">
							<Input.Password placeholder="پسورد" />
						</Form.Item>

						<Form.Item className="flex justify-center">
							<Button
								type="primary"
								htmlType="submit"
								className="text-lg"
								style={{ width: "100px" }}>
								ورود
							</Button>
						</Form.Item>
					</Form>
				</div>
			</Spin>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import { Avatar, Image, Typography } from "antd";
import { FaBars } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import MobileSidebar from "../SideBar/MobileSidebar";
import { GoScreenFull } from "react-icons/go";
import screenfull from "screenfull";

export default function Header() {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const storedUser = localStorage.getItem("userInfo");
		if (storedUser) {
			setUser(JSON.parse(storedUser));
		}
	}, []);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};
	// fullscreen
	const toggleFullscreen = () => {
		if (screenfull.isEnabled) {
			screenfull.toggle();
		}
	};
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 768) {
				setIsSidebarOpen(false);
			}
		};

		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="relative">
			<div className="w-full mx-auto flex justify-between px-4 py-[6px] items-center bg-white border-b border-b-gray-300">
				<div className="block lg:hidden">
					<FaBars onClick={toggleSidebar} />
				</div>
				<div className="justify-between hidden w-40 lg:flex">
					<Image
						width={40}
						height={40}
						src="/logo192.png"
						className="rounded-full"
						preview={false}
					/>
				<div className="">
				<GoScreenFull
					onClick={toggleFullscreen}
					title="فول اسکرین"
					className="text-4xl text-blue-500 cursor-pointer hover:text-gray-800"
					/>
					</div>
				</div>
				<Typography.Text
					className="hidden text-3xl font-bold text-textColor md:block"
					style={{ fontFamily: "BNazanin" }}>
					سیستم مدیریتی فیش های صرافی
				</Typography.Text>
				<div className="flex items-center justify-center gap-2 w-fit">
					<Avatar
						className="flex items-center justify-center"
						style={{
							width: 40,
							height: 40,
							backgroundColor: "#fde3cf",
							color: "#f56a00",
						}}>
						{user ? user.lastName[0] : "U"}
					</Avatar>
					<Typography.Text
						className="text-xl font-bold"
						style={{ fontFamily: "BNazanin" }}>
						{user ? user.lastName : "کاربر ناشناس"}
					</Typography.Text>
				</div>
			</div>

			{/* Sidebar and Overlay */}
			{isSidebarOpen && (
				<>
					<div
						className="fixed inset-0 z-40 bg-black bg-opacity-50"
						onClick={toggleSidebar}></div>
					<div className="fixed inset-y-0 right-0 w-[70%] bg-white z-50 shadow-lg">
						<div className="flex items-center justify-between p-4 border-b border-gray-300">
							<Typography.Text
								className="text-xl font-bold"
								style={{ fontFamily: "BNazanin" }}>
								سیستم مدیریتی فیش‌ها صرافی
							</Typography.Text>
							<FaTimes onClick={toggleSidebar} />
						</div>
						<MobileSidebar toggleSidebar={toggleSidebar} />
					</div>
				</>
			)}
		</div>
	);
}

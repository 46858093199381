import { Card, Space, Statistic } from "antd";
import React from "react";
import { Spin } from "antd";
export default function CardHome({ title, value, icon }) {
	return (
		<Card className="w-full">
			<Space direction="horizontal">
				<span>{icon}</span>
				{!value ? (
					<Spin />
				) : (
					<Statistic
						title={
							<span className="text-textColor text-base font-semibold">
								{title}
							</span>
						}
						value={value}
						valueStyle={{
							color: "inherit",
							fontSize: "1rem",
							fontWeight: "600",
						}}
					/>
				)}
			</Space>
		</Card>
	);
}

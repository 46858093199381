import React, { useEffect, useState } from "react";
import "../Customer/Customer.css";
import HeaderContent from "../../Components/HeaderContent/HeaderContent";
import { Button, Input, Modal, notification, Popconfirm, Space } from "antd";
import { MdAdd, MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";
import TableComponent from "../../Components/Table/TableComponent";
import { useApolloClient } from "@apollo/client";
import { CUSTOMERS } from "../../GraphQL/Query";
import { DELETE_CUSTOMER, UPDATE_CUSTOMER } from "../../GraphQL/Mutation";
import { CREATE_CUSTOMER } from "../../GraphQL/Mutation";

export default function Customer() {
	const client = useApolloClient();
	const [dataSource, setDataSource] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [filteredData, setFilteredData] = useState([]); // Add this state for filtered data
	const [newCustomer, setNewCustomer] = useState({
		firstName: "",
		lastName: "",
		address: "",
		phoneNumber: "",
	});
	const [selectedCustomer, setSelectedCustomer] = useState();
	const [userRole, setUserRole] = useState();

	useEffect(() => {
		loadCustomer();
		const userInfo = localStorage.getItem("userInfo");
		setUserRole(JSON.parse(userInfo));
	}, [refresh]);
	const columns = [
		{
			title: "#",
			dataIndex: "num",
			key: "num",
			// render: (text) => <a>{text}</a>,
		},
		{
			title: "نام",
			dataIndex: "firstName",
			key: "firstName",
		},
		{
			title: "فامیل",
			dataIndex: "lastName",
			key: "lastName",
		},
		{
			title: "آدرس",
			dataIndex: "address",
			key: "address",
		},
		{
			title: "موبایل",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
		},
		{
			title: userRole?.role === "Admin" && "عملیات",
			key: "action",
			render: (_, record) =>
				userRole?.role === "Admin" && (
					<Space size="middle">
						<MdOutlineEdit
							color="blue"
							className="text-base cursor-pointer"
							onClick={() => handleEditCustomer(record?.id)}
						/>
						<Popconfirm
							title="آیا مطمین هستید میخواهید حذف نمایید؟"
							onConfirm={() => handleDeleteCustomer(record?.id)}
							okText="بله"
							cancelText="انصراف"
							placement="topLeft">
							<MdOutlineDeleteOutline
								className="text-base cursor-pointer"
								color="red"
							/>
						</Popconfirm>
					</Space>
				),
		},
	];
	// function for getCustomer
	async function loadCustomer(page = 1, searchItem = "") {
		setLoading(true);
		try {
			const res = await client.query({
				query: CUSTOMERS,
				variables: {
					filter: { page, searchItem },
				},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setTotalCount(res.data.getCustomers.totalCount);
				// Map over the data to add the `num` field
				const modifiedData = res.data.getCustomers.customers.map(
					(item, index) => ({
						...item,
						num: (page - 1) * 10 + (index + 1), // Adjust index based on page number
					})
				);
				setDataSource(modifiedData);
				setFilteredData(modifiedData);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for deleteCustomer
	async function handleDeleteCustomer(customerId) {
		try {
			const res = await client.mutate({
				mutation: DELETE_CUSTOMER,
				variables: {
					customerId,
				},
			});
			if (res) {
				setRefresh(!refresh);
				notification.success({
					message: "مشتری با موفقیت حذف شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function for createCustomer
	async function handleCreateCustomer(e) {
		e.preventDefault();
		try {
			const res = await client.mutate({
				mutation: CREATE_CUSTOMER,
				variables: {
					data: {
						firstName: e.target.firstName.value,
						lastName: e.target.lastName.value,
						address: e.target.address.value,
						phoneNumber: e.target.phoneNumber.value,
					},
				},
			});
			if (res) {
				setIsAdding(!isAdding);
				setRefresh(!refresh);
				notification.success({
					message: "مشتری جدید با موفقیت اضافه شد",
					placement: "topRight",
					duration: 3,
				});
				resetAdding();
			}
		} catch (error) {
			console.log(error);
		}
	}
	// function fot editUser
	function handleEditCustomer(customerId) {
		const filteredCustomer = dataSource.find((item) => item.id === customerId);
		setSelectedCustomer(filteredCustomer);
		setIsEditing(true);
	}
	// function for updateUser
	async function handleUpdateCustomer(e) {
		e.preventDefault();
		const customerId = selectedCustomer.id;
		try {
			const res = await client.mutate({
				mutation: UPDATE_CUSTOMER,
				variables: {
					customerId,
					data: {
						firstName: e.target.firstName.value,
						lastName: e.target.lastName.value,
						address: e.target.address.value,
						phoneNumber: e.target.phoneNumber.value,
					},
				},
			});
			if (res) {
				setIsEditing(!isEditing);
				setRefresh(!refresh);
				notification.success({
					message: "کاربر جدید با موفقیت ویرایش شد",
					placement: "topRight",
					duration: 3,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	const handleAdd = () => {
		setIsAdding(true);
		setNewCustomer({
			firstName: "",
			lastName: "",
			accountName: "",
			accountNumber: "",
		});
	};

	const resetAdding = () => {
		setIsAdding(false);
		setNewCustomer({
			firstName: "",
			lastName: "",
			accountName: "",
			accountNumber: "",
		});
	};
	return (
		<div className="px-4 space-y-4">
			{/* edit customer */}
			<Modal
				title="ویرایش کاربر"
				className="custom-modal"
				width={420}
				open={isEditing}
				onCancel={() => setIsEditing(!isEditing)}
				footer={null}>
				<form onSubmit={handleUpdateCustomer}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام"
								id="firstName"
								name="firstName"
								value={selectedCustomer?.firstName || ""}
								onChange={(e) =>
									setSelectedCustomer({
										...selectedCustomer,
										firstName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="فامیل"
								id="lastName"
								name="lastName"
								value={selectedCustomer?.lastName || ""}
								onChange={(e) =>
									setSelectedCustomer({
										...selectedCustomer,
										lastName: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="آدرس"
								id="address"
								name="address"
								value={selectedCustomer?.address || ""}
								onChange={(e) =>
									setSelectedCustomer({
										...selectedCustomer,
										address: e.target.value,
									})
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شماره تماس"
								id="phoneNumber"
								name="phoneNumber"
								maxLength={15}
								value={selectedCustomer?.phoneNumber || ""}
								onChange={(e) => {
									const value = e.target.value.replace(/[^0-9]/g, "");
									setSelectedCustomer({
										...selectedCustomer,
										phoneNumber: value,
									});
								}}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							ویرایش کاربر
						</Button>
					</div>
				</form>
			</Modal>

			{/* add customer */}
			<Modal
				title="افزودن مشتری"
				className="custom-modal"
				width={420}
				open={isAdding}
				onCancel={() => setIsAdding(!isAdding)}
				footer={null}>
				<form onSubmit={handleCreateCustomer}>
					<div className="flex flex-col justify-center gap-2 mt-4">
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="نام"
								id="firstName"
								name="firstName"
								value={newCustomer.firstName}
								onChange={(e) =>
									setNewCustomer({ ...newCustomer, firstName: e.target.value })
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="فامیل"
								id="lastName"
								name="lastName"
								value={newCustomer.lastName}
								onChange={(e) =>
									setNewCustomer({ ...newCustomer, lastName: e.target.value })
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="آدرس"
								id="address"
								name="address"
								value={newCustomer.address}
								onChange={(e) =>
									setNewCustomer({ ...newCustomer, address: e.target.value })
								}
							/>
						</div>
						<div className="flex gap-2 sm:w-96">
							<Input
								placeholder="شماره تماس"
								id="phoneNumber"
								name="phoneNumber"
								maxLength={15}
								value={newCustomer.phoneNumber}
								onChange={(e) => {
									const value = e.target.value.replace(/[^0-9]/g, "");
									setNewCustomer({
										...newCustomer,
										phoneNumber: value,
									});
								}}
							/>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							icon={<MdAdd />}
							style={{ width: "100px", margin: "0 auto" }}>
							افزودن مشتری
						</Button>
					</div>
				</form>
			</Modal>

			{/* UI */}
			<HeaderContent
				handleAdd={handleAdd}
				search="جستجو مشتری"
				onSearch={(value) => loadCustomer(page, value)}
			/>
			<TableComponent
				columns={columns}
				data={filteredData}
				loading={loading}
				pagination={{
					pageSize: 10, // Number of records per page
					total: totalCount,
					onChange: (current) => {
						setPage(current); // Update page state
						loadCustomer(current); // Fetch the new page data
					},
					current: page, // Track the current page
					showLessItems: true, // Optional: Keep pagination UI minimal
				}}
			/>
		</div>
	);
}

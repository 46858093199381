import { Card, Image, Typography } from "antd";
import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa6";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { TfiWorld } from "react-icons/tfi";

export default function AboutUs() {
  return (
    <div>
      <Card className="flex items-start justify-center w-full">
        <div className="flex items-center justify-between w-full">
          <Typography.Title level={3}>
            معرفی تیک لند (TechLand)
          </Typography.Title>
          <div className="flex items-end justify-end w-36">
            <Image src="/techLand.png" preview={false} />
          </div>
        </div>
        <div className="w-full">
          <Typography.Text className="text-sm font-medium">
            دنیا سراسر شگفتی هست و ما با تفاوتی که ایجاد میکنیم میتوانیم به این
            شگفتی ها بیفزاییم ! تیک لند (TechLand) اینک با تیمی فوق العاده خبره
            با انبوهی از تجربه در طراحی و مدیریت ویبسایت، اپلکشن موبایل (اندروید
            و آیفون)، دیتابس و سیستم های مدیریتی قدم به خلق سیستم های متفاوت
            گذاشته و میتواند مطلوب ترین گزینه برای تعریف متفاوت شما در بازار کار
            و مدیریت باشد. شما با ما میتوانید نوعی دیگر معرفی شوید و همیشه بروز
            باقی بمانید و ما از این لحاظ اطمنیان داریم که میتوانیم اطلاعات شما
            را به نوعی خاص متناسب با تکنولوژی های جدید مدیریت نماییم.
          </Typography.Text>
        </div>
        <div className="flex flex-col justify-between w-full gap-4 mt-10 sm:flex-row">
          <div className="flex flex-col items-center space-y-3 sm:w-1/2 sm:items-start">
            <Typography.Title level={4}>ارتباط با ما</Typography.Title>
            <div className="flex items-center w-64 gap-4">
              <span>
                <FaPhoneAlt className="text-blue-400" />
              </span>
              <Typography.Text className="flex flex-col text-sm font-semibold">
                <span dir="ltr">+93 796 14 65 14 - +93 790 88 33 62</span>
              </Typography.Text>
            </div>
            <div className="flex items-center w-64 gap-4">
              <span>
                <IoMdMail className="text-blue-400" />
              </span>
              <Typography.Text className="flex flex-col text-sm font-semibold">
                <span dir="ltr">technlogyLand01@gmail.com</span>
              </Typography.Text>
            </div>
            <div className="flex items-center gap-4">
              <a
                href="https://techlands.online/"
                className="text-xl text-rose-300"
              >
                <TfiWorld className="" />
              </a>

              <a
                href="https://t.me/Mh_Yousuf"
                className="text-xl text-blue-400"
              >
                <FaTelegram />
              </a>
              <a
                href="https://wa.me/93790883362"
                className="text-xl text-green-400"
              >
                <IoLogoWhatsapp />
              </a>
            </div>

            <div className="flex items-center w-64 gap-4">
              <div className="w-32">
                <Image
                  src="/customerBarcod.jpeg"
                  className="w-40"
                  preview={false}
                />
              </div>
            </div>
          </div>
          <div className="lg:w-1/3">
            <Image src="/about.jpg" className="w-1/2" preview={false} />
          </div>
        </div>
      </Card>
    </div>
  );
}

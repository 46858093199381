import React, { useEffect, useState } from "react";
import CardHome from "./CardHome";
import { FaMoneyCheckAlt, FaCreditCard, FaUsers } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";

import { Card } from "antd";
import Chart from "./Chart";
import { useApolloClient } from "@apollo/client";
import { STATISTIC } from "../../GraphQL/Query";
export default function HomePage() {
	const [statistic, setStatistic] = useState({
		accountsCount: 0,
		billsCount: 0,
		customersCount: 0,
		usersCount: 0,
	});
	const client = useApolloClient();
	useEffect(() => {
		loadStatistic();
	}, []);
	async function loadStatistic() {
		try {
			const res = await client.query({
				query: STATISTIC,
				variables: {},
				fetchPolicy: "no-cache",
			});
			if (res) {
				setStatistic({
					accountsCount: res.data.getStatistic.accountsCount,
					billsCount: res.data.getStatistic.billsCount,
					customersCount: res.data.getStatistic.customersCount,
					usersCount: res.data.getStatistic.usersCount,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<>
			<div className="sm:w-[80%] w-full gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
				<CardHome
					title={"فیش ها"}
					value={statistic.billsCount}
					icon={
						<div className="w-5 h-5 bg-green-400/20 rounded-full p-5  relative flex items-center justify-center">
							{" "}
							<FaMoneyCheckAlt className="text-2xl text-green-500 z-10 flex items-center justify-center absolute" />
						</div>
					}
				/>
				<CardHome
					title={"مشتریان"}
					value={statistic.customersCount}
					icon={
						<div className="w-5 h-5 bg-blue-400/20 rounded-full p-5  relative flex items-center justify-center">
							{" "}
							<IoIosPeople className="text-2xl text-blue-300 z-10 flex items-center justify-center absolute" />
						</div>
					}
				/>
				<CardHome
					title={"کاربران"}
					value={statistic.usersCount}
					icon={
						<div className="w-5 h-5 bg-purple-400/20 rounded-full p-5  relative flex items-center justify-center">
							{" "}
							<FaUsers className="text-2xl text-purple-400 z-10 flex items-center justify-center absolute" />
						</div>
					}
				/>
				<CardHome
					title={"حسابات"}
					value={statistic.accountsCount}
					icon={
						<div className="w-5 h-5 bg-red-400/20 rounded-full p-5  relative flex items-center justify-center">
							{" "}
							<FaCreditCard className="text-2xl text-red-400 z-10 flex items-center justify-center absolute" />
						</div>
					}
				/>
			</div>
			<div className="sm:w-4/5 w-full">
				<Card style={{ marginTop: 10 }}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Chart />
					</div>
				</Card>
			</div>
		</>
	);
}
